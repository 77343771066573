
.student-manage {
    box-sizing: border-box;
    height: 100%;
    padding: 30px 30px 0;
    .hidden-box {
        display: none;
    }
    .operate-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .left-filter {
            display: flex;
            align-items: center;
            color: #332d42;
            font-size: 14px;
            .label {
                margin-right: 10px;
            }
            ::v-deep .el-select {
                width: 250px;
                .el-icon-arrow-up:before {
                    content: "\e78f";
                    color: #342e43;
                    font-size: 20px;
                }
            }
        }
        .right-operate-button {
            .plain-button {
                color: #2821fc;
                border-color: #2821fc;
            }
            ::v-deep .el-button + .el-button {
                margin-left: 19px;
            }
        }
    }
}
